import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, withPrefix } from 'gatsby';

function Seo({ title, description, url, image, site }) {
  // Seo defaults
  const siteTitle = site.title;
  const socialImage = image || withPrefix(site.siteSocialImage);

  return (
    <Helmet
      title={title}
      defaultTitle={siteTitle}
      titleTemplate={
        site.title !== title ? `%s | ${site.title}` : `${site.title}`
      }
    >
      {site?.siteLocale && <html lang={site.siteLocale} />}

      {/* Basics */}
      {description && <meta name="description" content={description} />}

      {/* Facebook (Open Graph) */}
      {site?.siteLocale && (
        <meta property="og:locale" content={site.siteLocale} />
      )}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {socialImage && <meta property="og:image" content={socialImage} />}
      {url && <meta property="og:url" content={url} />}
      {siteTitle && <meta property="og:site_name" content={siteTitle} />}
      {site?.facebookAppId && (
        <meta property="fb:app_id" content={site.facebookAppId} />
      )}

      {/* Twitter */}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {socialImage && <meta name="twitter:image" content={socialImage} />}
      {socialImage && (
        <meta name="twitter:card" content="summary_large_image" />
      )}
      {url && <meta name="twitter:url" content={url} />}
      {site?.twitterHandle && (
        <meta name="twitter:site" content={site.twitterHandle} />
      )}
    </Helmet>
  );
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
};

Seo.defaultProps = {
  title: null,
  description: null,
  url: null,
  image: null,
};

/**
 * Query:
 * Getting defaults from global data set.
 */
export default props => (
  <StaticQuery
    query={graphql`
      query GlobalSeoQuery {
        site {
          siteMetadata {
            siteTitle
            siteUrl
            siteLocale
            siteSocialImage
            twitterHandle
            facebookAppId
          }
        }
        craft {
          globals {
            globalSeo {
              title: globalSeoTitle
              description: globalSeoDescription
              image: globalSeoImage {
                socialMediaUrl: url(transform: transformSocialMedia)
              }
            }
          }
        }
      }
    `}
    render={data => {
      // Merge defaults with given props so we don't
      // have to handle too much conditional data logic in the component itself
      const mergedProps = {
        ...data.craft.globals.globalSeo,
        title: props.title ? props.title : data.craft.globals.globalSeo?.title,
        description: props.description
          ? props.description
          : data.craft.globals.globalSeo?.description,
        image:
          props.image ||
          data.craft.globals.globalSeo?.image?.[0]?.socialMediaUrl,
      };

      return (
        <Seo
          site={{
            title: data.site.siteMetadata.siteTitle,
            url: data.site.siteMetadata.siteUrl,
            locale: data.site.siteMetadata.siteLocale,
            twitterHandle: data.site.siteMetadata.twitterHandle,
            facebookAppId: data.site.siteMetadata.facebookAppId,
            titleTemplate: data.site.siteMetadata.titleTemplate,
          }}
          {...mergedProps}
        />
      );
    }}
  />
);
