import React from 'react';
import { graphql } from 'gatsby';

import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

import Seo from 'components/container/Seo';
import Image from 'components/common/Image';
import Headline from 'components/common/Headline';
import HeadlineGold from 'components/common/HeadlineGold';
import Text from 'components/common/Text';
import Button from 'components/common/Button';

export const Error404Styled = styled('div')({
  position: 'relative',
  height: '100vh',
  img: { objectPosition: '35% center !important' },

  [media('tabletFluid')]: {
    img: { objectPosition: 'center center !important' },
  },
});

export const Error404HeadlinesStyled = styled('div')({
  position: 'absolute',
  zIndex: 3,
  top: '32%',
  right: '5%',
  width: 150,
  height: 240,

  [media(380)]: {
    right: '10%',
    width: 180,
  },

  [media('tabletFluid')]: {
    right: '32%',
    width: 250,
    height: 240,
  },
});

export const BackgroundImageStyled = styled(Image)({
  zIndex: '1',
  height: '100vh',
});

export const TextStyled = styled(Text)({
  marginTop: 15,
  lineHeight: 1.7,
});

export const ButtonStyled = styled(Button)({
  marginTop: 40,
});

export default function PageStart({ data, pageContext }) {
  const url = pageContext?.url;
  const { backgroundImage, title, seoTitle, seoDescription, seoImage } = data;

  return (
    <>
      <Seo
        title={seoTitle || title}
        description={seoDescription}
        url={url}
        image={seoImage?.[0]?.socialMediaUrl}
      />
      <Error404Styled>
        <BackgroundImageStyled src={backgroundImage} />
        <Error404HeadlinesStyled>
          <Headline element="h5">Error 404</Headline>
          <HeadlineGold element="h2">
            Hier gibt’s <b>nichts zu sehen.</b>#404
          </HeadlineGold>
          <TextStyled>
            Probier‘ es lieber auf unserer Website. Oder im Shop. Da gibt’s immer erfrischende Inhalte.
          </TextStyled>
          <ButtonStyled to="/">Startseite</ButtonStyled>
          <ButtonStyled to="/online-shops">Shop</ButtonStyled>
        </Error404HeadlinesStyled>
      </Error404Styled>
    </>
  );
}

export const query = graphql`
  query QueryError404 {
    craft {
      entries {
        ... on Craft_Error404 {
          title
          seoTitle
          seoDescription
          seoImage {
            socialMediaUrl: url(transform: transformSocialMedia)
          }
        }
      }
    }
    backgroundImage: file(relativePath: { eq: "404/bg.jpg" }) {
      ...imageDefault
    }
  }
`;
